import React from 'react';
import papperPlaneImg from '../../../../assets/img/paper-plane.svg';
import FeatureBlock from '../../../Common/FeatureBlock';
import { formatDate } from '../../../../helpers/commonHelper';
import ArticlePopupVideo from './ArticlePopupVideo';
import ArticlePopupImageGallery from './ArticlePopupImageGallery';
import ArticlePopupImageCarousel from './ArticlePopupImageCarousel';
import ArticlePopupQuote from './ArticlePopupQuote';
import ShareContentButton from '../../../Common/ShareContentButton';

interface IProps {
  article: any,
  showShareBtn: boolean,
  bodyContainerClass?: string,
  showGalleryBlock?: boolean,
  parentRef?: any,
}

const ArticleDetail = (props: React.PropsWithChildren<IProps>) => {
  const {
    article,
    showShareBtn,
    bodyContainerClass,
    showGalleryBlock,
    parentRef,
  } = props;

  if (!article) {
    return null;
  }

  const isHeadMsg = article && article.type === 'heads_message';
  return (
    <>
      {!isHeadMsg ? (
        <div className="w-full pt-6 px-5 md:px-12">
          <div className="block rounded-lg lg:rounded-xl shadow-md bg-white overflow-hidden">
            <div className="post-card-head block relative overflow-hidden h-80">
              <img className="object-contain object-center w-full h-80" src={article.header_image?.high_path} alt={`Article - ${article.title}`} />
            </div>
            <div className="px-5 py-3 flex justify-between">
              <div className="flex items-center gap-2 flex-wrap">
                <span className="text-sm font-bold">Article</span>
                <div className="w-2 h-2 bg-themered rounded-full flex-none inline-block" />
                <div className="text-gray-500 font-medium text-xs">{`${article.time_to_read} min${article.time_to_read !== 1 ? 's' : ''} read`}</div>
              </div>
              <span className="text-xs">{formatDate(article.published_date, 'DD-MM-yyyy', 'DD MMMM yyyy')}</span>
            </div>
          </div>
        </div>
      ) : (
        <>
          {article.header_image?.high_path && (
            <div className="relative overflow-hidden bg-navy rounded-b-3xl">
              <img className="object-contain object-center w-full h-80" src={article.header_image.high_path} alt={`Head - ${article.title}`} />
            </div>
          )}
        </>
      )}
      <div className={`py-5 px-5 md:px-12 ${bodyContainerClass}`}>
        {isHeadMsg && (
          <div className="flex relative mb-6">
            {article.author_image?.high_path && (
              <div className="overflow-hidden absolute bottom-0 rounded-lg shadow-xl flex-none">
                <img src={article.author_image?.high_path} alt="author" className="w-28 h-28 object-cover object-top min-w-full" />
              </div>
            )}
            <div className={article.author_image ? 'pl-32 pb-4' : ''}>
              <span className="text-xs">{formatDate(article.published_date, 'DD-MM-yyyy', 'DD MMMM yyyy')}</span>
              <div className="text-sm font-bold">{`${article.time_to_read} min${article.time_to_read !== 1 ? 's' : ''} read`}</div>
            </div>
          </div>
        )}
        <div className="post-detail-info">
          {isHeadMsg && <div className="text-base text-themered font-thin">{article.type === 'heads_message' ? 'Head’s Message' : 'Article'}</div>}
          <h2 className="h2 pb-5">{article.title}</h2>
          {article.intro && (
            <div
              className="pb-6"
              dangerouslySetInnerHTML={{ __html: article?.intro }}
            />
          )}
          {article.body_content?.map((bodyItem: any) => {
            if (!bodyItem.value) {
              return null;
            }

            if (bodyItem.type === 'text') {
              return (
                <div
                  key={bodyItem.id}
                  className="pb-6"
                  dangerouslySetInnerHTML={{ __html: bodyItem.value }}
                />
              );
            }

            if (bodyItem.type === 'quote') {
              return (
                <ArticlePopupQuote key={bodyItem.id} quote={bodyItem.value} />
              );
            }

            if (bodyItem.type === 'video') {
              const videoValue = bodyItem.value;
              const isArrayValue = Array.isArray(videoValue);
              return (
                <ArticlePopupVideo
                  key={bodyItem.id}
                  id={bodyItem.id}
                  videoUrl={(isArrayValue && videoValue[0]?.file_path) || videoValue}
                  videoThumb={
                    (isArrayValue && videoValue[0]?.high_path) || ''
                  }
                />
              );
            }

            if (bodyItem.type === 'image_gallery') {
              return (
                <ArticlePopupImageGallery key={bodyItem.id} imageGallery={bodyItem.value} />
              );
            }

            if (bodyItem.type === 'image_carousel') {
              return (
                <ArticlePopupImageCarousel key={bodyItem.id} imageCarousel={bodyItem.value} />
              );
            }
            return null;
          })}
          {showGalleryBlock && (
            <FeatureBlock
              title="View this week’s gallery"
              description=""
              routePath="/gallery"
              image={papperPlaneImg}
              imgClass="w-14 lg:w-16"
            />
          )}
          {showShareBtn && (
            <ShareContentButton
              contentId={article.id}
              contentType="article"
              contentTitle={article.title}
              contentDate={formatDate(article.published_date, 'DD-MM-yyyy', 'DD MMM yyyy')}
              parentRef={parentRef}
            />
          )}
        </div>
      </div>
    </>
  );
};

ArticleDetail.defaultProps = {
  parentRef: null,
  bodyContainerClass: null,
  showGalleryBlock: false,
};

export default ArticleDetail;
